import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '../components/Typography.tsx';
import AppAppBar from '../views/AppAppBar.tsx';
import AppForm from '../views/AppForm.tsx';
import TextField from '@mui/material/TextField';
import withRoot from '../modules/withRoot.tsx';
import { useFlagsmith, useFlags } from 'flagsmith/react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const [sending, setSending] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const flags = useFlags(['invitations_allowed']);
  const phoneNumber = "59176543682";
  const message = "Hola, perdona no puedo ingresar a la invitación de la boda de Rossy y Roly";

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  useEffect(() =>{
    if (flags.invitations_allowed.enabled) {
      navigate('/Home');
    } else if(sending) {
      setFullName('')
      setPhone('')
      setError(true)
      setSending(false)
    }
  }, [flags, sending])

  const flagsmith = useFlagsmith();
  const identify = (phone: string) => {
    setSending(true);
    flagsmith.identify(`${phone}`, {fullname: fullName});
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Ingresa
          </Typography>
        </React.Fragment>
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', '& .MuiTextField-root': { my: 1, bgcolor: 'white' }, }}>
            <TextField
              id="outlined-basic"
              error={error}
              label="Nombre y Apellidos"
              variant="outlined"
              value={fullName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFullName(event.target.value);
              }}
            />
            <TextField
              id="outlined-basic" 
              error={error}
              label="Telefono"
              variant="outlined"
              type="number"
              value={phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(event.target.value);
              }} 
            />
            <Button
              color="secondary"
              size="large"
              variant="contained"
              disabled={!fullName || !phone}
              sx={{ borderRadius: 1, my: 2 }}
              onClick={() => {
                identify(phone);
              }}
            >
              {sending ? 'Ingresando' : 'Ingresar'}
            </Button>
            {error && (
              <button onClick={handleClick} style={{ padding: '10px 20px', backgroundColor: '#25D366', color: 'white', border: 'none', borderRadius: '5px' }}>
                ¿Crees que hubo un error al intentar ingresar? Contactanos.
              </button>
            )}
          </Box>
      </AppForm>
    </React.Fragment>
  );
}

export default withRoot(SignIn);