import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from 'react-image-gallery';
import Typography from './Typography.tsx';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Box } from '@mui/material';

const images = [
    {
      original: require('../assets/imgs/rr1.jpg'),
      thumbnail: require('../assets/imgs/rr1.jpg'),
    },
    {
      original: require('../assets/imgs/rr2.jpg'),
      thumbnail: require('../assets/imgs/rr2.jpg'),
    },
    {
      original: require('../assets/imgs/rr3.jpg'),
      thumbnail: require('../assets/imgs/rr3.jpg'),
    },
    {
      original: require('../assets/imgs/rr4.jpg'),
      thumbnail: require('../assets/imgs/rr4.jpg'),
    },
    {
      original: require('../assets/imgs/rr5.jpg'),
      thumbnail: require('../assets/imgs/rr5.jpg'),
    },
    {
      original: require('../assets/imgs/rr6.jpg'),
      thumbnail: require('../assets/imgs/rr6.jpg'),
    },
  ];

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const Gallery  = (props) => {
    return (
    <div className='gallery' id='gallery'>
      <Box sx={item}>
        <Typography variant="h5" align="center" sx={{fontFamily: 'parisienne', textAlign: 'center', fontSize: '30px'}} my={2} >
        {
          'Gracias señor porque, desde que nos conocimos aprendimos a compartir la amistad, cariño, '
        }
        {
          'felicidad y amor. Con tu prodigiosa bendición, uniremos nuestras vidas hasta la eternidad'
        }
      </Typography>
      </Box>

      <ImageGallery
        autoPlay={true}
        items={images}
      />
    </div>
    );
  }
export default Gallery;
