import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

type SVGCustomIcon = {
  iconName: string
  size?: number
}

const SVGCustomIcon: React.FC<SVGCustomIcon> = ({iconName, size}) => {
  const Icon = () => {
    switch (iconName) {
      case 'bible':
        return (          
          <SvgIcon>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
            <g>
              <g>
                <path d="M342.578,119.73h-33.801V88.502c0-4.442-3.601-8.043-8.043-8.043h-30.44c-4.442,0-8.043,3.601-8.043,8.043v31.229h-33.801
                  c-4.442,0-8.043,3.601-8.043,8.043v30.44c0,4.442,3.601,8.043,8.043,8.043h33.801v88.969c0,4.442,3.601,8.043,8.043,8.043h30.44
                  c4.442,0,8.043-3.601,8.043-8.043v-88.969h33.801c4.442,0,8.043-3.601,8.043-8.043v-30.44
                  C350.621,123.331,347.02,119.73,342.578,119.73z M334.536,150.172h-33.801c-4.442,0-8.043,3.601-8.043,8.043v88.969h-14.355
                  v-88.969c0-4.442-3.601-8.043-8.043-8.043h-33.801v-14.355h33.801c4.442,0,8.043-3.601,8.043-8.043V96.545h14.355v31.229
                  c0,4.442,3.601,8.043,8.043,8.043h33.801V150.172z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M390.364,0H154.638h-33.003C92.353,0,68.53,23.823,68.53,53.104v24.957c0,4.442,3.601,8.043,8.043,8.043
                  s8.043-3.601,8.043-8.043V53.104c0-20.412,16.607-37.019,37.019-37.019h24.96v299.537c-0.171,0.016-0.34,0.038-0.51,0.055
                  c-0.201,0.02-0.4,0.043-0.601,0.064c-17.857,1.893-35.044,9.665-48.405,21.631c-4.168,3.732-7.951,7.897-11.316,12.365
                  c-0.096,0.129-0.19,0.26-0.286,0.388c-0.288,0.388-0.579,0.775-0.861,1.169V110.234c0-4.442-3.601-8.043-8.043-8.043
                  c-4.442,0-8.043,3.601-8.043,8.043v291.123c0,47.479,38.628,86.107,86.108,86.107h45.403v16.493c0,3.229,1.93,6.144,4.903,7.404
                  c2.97,1.261,6.409,0.622,8.731-1.624l25.843-24.994l25.843,24.994c1.53,1.48,3.545,2.262,5.592,2.262
                  c1.058,0,2.125-0.209,3.138-0.639c2.973-1.26,4.903-4.176,4.903-7.404v-16.493h73.988c4.442,0,8.043-3.601,8.043-8.043
                  c0-4.442-3.601-8.043-8.043-8.043h-73.988v-25.156h148.388v25.157H385.04c-4.442,0-8.043,3.601-8.043,8.043
                  c0,4.442,3.601,8.043,8.043,8.043h50.385c4.443,0,8.044-3.601,8.044-8.043v-41.242v-73.643v-41.242V53.104
                  C443.468,23.823,419.646,0,390.364,0z M262.91,438.178v41.242v5.569l-17.8-17.216c-1.559-1.508-3.574-2.262-5.591-2.262
                  c-2.017,0-4.032,0.754-5.591,2.262l-17.8,17.216v-5.569v-41.242v-28.779h46.783V438.178z M427.384,430.136h-0.001H278.996v-28.779
                  c0-4.443-3.601-8.043-8.043-8.043h-62.868c-4.442,0-8.043,3.6-8.043,8.043v28.779h-45.403c-15.869,0-28.779-12.91-28.779-28.779
                  c0-15.869,12.91-28.779,28.779-28.779h272.745V430.136z M427.384,315.249h-0.001H225.67c-4.442,0-8.043,3.601-8.043,8.043
                  c0,4.442,3.601,8.043,8.043,8.043h201.714v25.157H154.639c-24.738,0-44.865,20.126-44.865,44.864
                  c0,24.738,20.126,44.865,44.865,44.865h45.403v25.157h-45.403c-38.61,0-70.022-31.412-70.022-70.021s31.412-70.022,70.022-70.022
                  h38.86c4.442,0,8.043-3.601,8.043-8.043s-3.601-8.043-8.043-8.043h-30.818V16.085h227.683c20.412,0,37.019,16.607,37.019,37.019
                  V315.249z"/>
              </g>
            </g>
            </svg>
          </SvgIcon>
        )
      case 'wedding-cake':
        return (          
          <SvgIcon>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" >
            <g>
              <g>
                <path d="M482.147,435.9v-40.41H459.49V289.812h-0.001h-0.315h-41.034v-89.219c0.08-1.072,0.135-2.149,0.135-3.241v-7.604h-0.135
                  h-41.35v-79.231c0.012-0.402,0.03-0.804,0.03-1.21v-7.604H309.91h-15.208h-5.27c14.888-13.157,34.885-32.093,40.143-43.192
                  l0.212-0.446c1.633-3.436,3.87-8.14,4.607-14.852c1.217-11.08-2.288-22.169-9.617-30.426C317.554,4.648,307.6,0.108,296.746,0.003
                  C286.112-0.107,276.6,4.027,268.84,12.038c-7.776-7.977-17.5-12.201-28.103-12.034c-10.682,0.155-20.515,4.684-27.689,12.751
                  c-7.359,8.276-10.925,19.38-9.785,30.468c0.717,6.971,3.009,11.602,4.683,14.982l0.207,0.417c5.43,11,25.562,30.076,40.194,43.082
                  h-5.344h-15.208h-66.909v0.015v7.589v80.44h-41.35v7.604v92.459H78.186v7.604v98.075H55.528V435.9h188.213v31.937h-42.961v44.164
                  h136.115v-44.164h-42.961V435.9H482.147z M418.14,305.02h24.813c-3.348,12.3-14.615,21.369-27.962,21.369
                  c-13.347,0-24.614-9.069-27.961-21.369H418.14z M402.934,289.811H386.02h-15.208h-57.948h-15.208h-57.948h-15.208h-57.948h-15.208
                  h-16.599v-59.663c7.511,6.384,17.228,10.25,27.836,10.25c14.685,0,27.669-7.396,35.442-18.654
                  c7.772,11.258,20.757,18.654,35.442,18.654c14.685,0,27.668-7.396,35.441-18.654c7.772,11.258,20.757,18.654,35.442,18.654
                  s27.669-7.396,35.442-18.654c7.772,11.258,20.756,18.654,35.441,18.654c10.544,0,20.21-3.817,27.703-10.135V289.811z
                  M369.797,305.02c-3.348,12.3-14.615,21.369-27.961,21.369c-13.347,0-24.614-9.069-27.962-21.369H369.797z M296.642,305.02
                  c-3.348,12.3-14.615,21.369-27.961,21.369c-13.347,0-24.614-9.069-27.961-21.369H296.642z M223.485,305.02
                  c-3.348,12.3-14.615,21.369-27.961,21.369c-13.347,0-24.614-9.069-27.961-21.369H223.485z M206.747,205.154h53.433
                  c-3.382,11.562-14.076,20.035-26.716,20.035C220.823,225.189,210.129,216.716,206.747,205.154z M277.63,205.154h53.433
                  c-3.382,11.562-14.076,20.035-26.716,20.035C291.705,225.189,281.012,216.716,277.63,205.154z M376.789,204.957h25.221
                  c-3.317,11.664-14.067,20.233-26.782,20.233c-12.641,0-23.333-8.473-26.716-20.035h28.278V204.957z M360.466,116.927
                  c-3.259,10.548-13.1,18.231-24.704,18.231c-11.604,0-21.445-7.684-24.704-18.231H360.466z M221.79,51.891l-0.214-0.433
                  c-1.413-2.854-2.748-5.55-3.185-9.791c-0.705-6.855,1.49-13.709,6.021-18.805c4.305-4.841,10.181-7.558,16.546-7.651
                  c0.118-0.001,0.235-0.002,0.353-0.002c8.41,0,15.518,4.422,21.129,13.15l6.354,9.882l6.414-9.843
                  c5.756-8.832,12.985-13.256,21.391-13.187c6.502,0.064,12.471,2.788,16.806,7.673c4.481,5.049,6.623,11.855,5.873,18.672
                  c-0.457,4.161-1.864,7.119-3.225,9.982l-0.22,0.464c-4.519,9.537-28.971,32.153-46.984,47.398
                  C250.952,84.178,226.56,61.554,221.79,51.891z M266.126,116.927l2.732,2.239l2.711-2.239h21.988
                  c-3.259,10.548-13.1,18.231-24.704,18.231c-11.604,0-21.445-7.684-24.704-18.231H266.126z M226.648,116.927
                  c-3.259,10.548-13.1,18.231-24.704,18.231c-11.604,0-21.445-7.684-24.704-18.231H226.648z M176.094,141.177
                  c7.064,5.741,16.06,9.19,25.851,9.19c13.791,0,26.005-6.842,33.455-17.3c7.45,10.459,19.664,17.3,33.455,17.3
                  c13.791,0,26.005-6.842,33.455-17.3c7.45,10.459,19.664,17.3,33.455,17.3c9.776,0,18.761-3.44,25.819-9.165v48.547h-14.19h-15.208
                  h-55.675h-15.208h-55.675h-15.208h-14.325V141.177z M160.886,204.955L160.886,204.955v0.199h28.413
                  c-3.382,11.562-14.076,20.035-26.716,20.035c-12.714,0-23.465-8.568-26.782-20.234H160.886z M119.536,305.02h30.794
                  c-3.348,12.3-14.615,21.369-27.961,21.369c-13.347,0-24.614-9.069-27.961-21.369H119.536z M93.394,330.728
                  c7.763,6.761,17.895,10.87,28.974,10.87c15.197,0,28.625-7.714,36.578-19.429c7.954,11.715,21.382,19.429,36.577,19.429
                  c15.197,0,28.625-7.714,36.578-19.429c7.954,11.715,21.381,19.429,36.578,19.429c15.197,0,28.625-7.714,36.578-19.429
                  c7.954,11.715,21.381,19.429,36.577,19.429c15.197,0,28.625-7.714,36.578-19.429c7.954,11.715,21.381,19.429,36.578,19.429
                  c11.229,0,21.486-4.218,29.29-11.142v65.036H93.394V330.728z M321.687,483.045v13.748h-105.7v-13.748h27.753h50.194H321.687z
                  M258.949,467.837V435.9h19.778v31.937H258.949z M243.741,420.691H70.736v-9.993h7.45h381.302h7.45v9.993H293.935H243.741z"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="486.187,127.495 478.637,135.044 471.01,127.416 460.256,138.17 478.637,156.552 496.941,138.248 		"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="129.957,46.655 122.407,54.205 114.78,46.577 104.026,57.331 122.407,75.712 140.711,57.409 		"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="40.991,235.583 33.441,243.133 25.813,235.505 15.06,246.258 33.441,264.641 51.744,246.338 		"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="398.968,450.026 391.418,457.575 383.79,449.947 373.037,460.701 391.418,479.082 409.722,460.779 		"/>
              </g>
            </g>
            </svg>
          </SvgIcon>
        )
      case 'wedding-arch':
        return (          
          <SvgIcon>
            <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496" >
            <g>
              <g>
                <g>
                  <path d="M480,480v-32h-16v-32h-18.088l-28.528-92.728C421.472,317.896,424,311.264,424,304c0-7.64-2.8-14.568-7.28-20.072
                    l32.144-208.944L267.032,0.592L265.568,0h-35.144L47.136,74.984L79.28,283.928C74.8,289.432,72,296.36,72,304
                    c0,7.264,2.528,13.896,6.624,19.272L50.088,416H32v32H16v32H0v16h208v-16h-16v-32h-16v-32h-18.088l-28.528-92.728
                    C133.472,317.896,136,311.264,136,304c0-7.808-2.92-14.88-7.584-20.44L143.336,112h45.352L248,171.312L307.312,112h45.352
                    l14.92,171.56C362.92,289.12,360,296.192,360,304c0,7.264,2.528,13.896,6.624,19.272L338.088,416H320v32h-16v32h-16v16h208v-16
                    H480z M176,480H32v-16h144V480z M160,432v16H48v-16H160z M141.168,416H66.832l25.336-82.328C95.832,335.144,99.816,336,104,336
                    s8.168-0.856,11.832-2.328L141.168,416z M88,304c0-8.824,7.176-16,16-16c8.816,0,16,7.176,16,16c0,8.824-7.184,16-16,16
                    C95.176,320,88,312.824,88,304z M306.064,90.624L248,148.688l-58.064-58.064C186.112,86.8,184,81.704,184,76.28
                    C184,65.104,193.104,56,204.28,56c5.424,0,10.512,2.112,14.344,5.936L248,91.312l29.376-29.376
                    C281.2,58.112,286.296,56,291.72,56C302.896,56,312,65.104,312,76.28C312,81.704,309.888,86.792,306.064,90.624z M367.336,96
                    h-45.192C325.928,90.176,328,83.4,328,76.28C328,56.272,311.728,40,291.72,40c-9.696,0-18.8,3.776-25.656,10.624L248,68.688
                    l-18.064-18.064C223.088,43.776,213.976,40,204.28,40C184.272,40,168,56.272,168,76.28c0,7.112,2.072,13.896,5.856,19.72h-45.192
                    l-15.432,177.52c-2.944-0.896-6-1.52-9.232-1.52c-3.552,0-6.904,0.72-10.096,1.784L64.864,85.016L233.568,16h28.856
                    l168.704,69.016l-29.04,188.768C398.904,272.72,395.544,272,392,272c-3.232,0-6.288,0.624-9.232,1.52L367.336,96z M408,304
                    c0,8.824-7.184,16-16,16c-8.824,0-16-7.176-16-16c0-8.824,7.176-16,16-16C400.816,288,408,295.176,408,304z M380.168,333.672
                    C383.832,335.144,387.816,336,392,336c4.184,0,8.168-0.856,11.832-2.328L429.168,416h-74.336L380.168,333.672z M336,432h112v16
                    H336V432z M464,480H320v-16h144V480z"/>
                  <rect x="168.002" y="127.997" transform="matrix(-0.6 -0.8 0.8 -0.6 163.1986 377.593)" width="15.992" height="40"/>
                  <rect x="299.998" y="140.007" transform="matrix(0.8 0.6 -0.6 0.8 152.8014 -162.3979)" width="40" height="15.992"/>
                </g>
              </g>
            </g>
            </svg>
          </SvgIcon>
        )
      case 'wedding-rings':
        return (
          <SvgIcon>
          <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <g>
                <path d="M344.144,151.961c-17.568,0-34.477,2.86-50.298,8.127c-13.873-21.093-32.678-38.76-54.624-51.247
                  c-23.907-13.603-51.165-20.793-78.827-20.793c-88.011,0-159.613,71.603-159.613,159.613c0,88.011,71.602,159.614,159.613,159.614
                  c17.566,0,34.477-2.86,50.298-8.127c13.874,21.093,32.678,38.76,54.624,51.247c23.907,13.603,51.165,20.793,78.827,20.793
                  c88.011,0,159.613-71.602,159.613-159.613C503.758,223.564,432.156,151.961,344.144,151.961z M160.395,391.633
                  c-79.386,0-143.971-64.585-143.971-143.972c0-79.386,64.585-143.971,143.971-143.971c47.793,0,91.57,23.135,118.512,62.222
                  c-5.026,2.26-9.914,4.773-14.649,7.523c-23.982-33.588-62.274-53.429-103.863-53.429c-70.39,0-127.657,57.267-127.657,127.657
                  s57.267,127.657,127.657,127.657s127.657-57.267,127.657-127.657c0-10.406-1.265-20.729-3.74-30.743
                  c4.706-2.985,9.649-5.627,14.792-7.895c3.482,12.525,5.263,25.486,5.263,38.637C304.367,327.048,239.781,391.633,160.395,391.633z
                  M190.666,355.518c-9.633,2.707-19.786,4.16-30.272,4.16c-61.765,0-112.015-50.25-112.015-112.015s50.25-112.015,112.015-112.015
                  c36.252,0,69.658,17.178,90.73,46.3c-40.3,29-66.594,76.299-66.594,129.629C184.531,326.539,186.597,341.285,190.666,355.518z
                  M232.129,311.574c0-33.613,14.885-63.811,38.404-84.36c1.238,6.716,1.877,13.559,1.877,20.448
                  c0,33.612-14.885,63.811-38.404,84.36C232.768,325.306,232.129,318.463,232.129,311.574z M313.877,203.717
                  c9.631-2.706,19.782-4.158,30.267-4.158c61.765,0,112.015,50.25,112.015,112.015S405.91,423.59,344.144,423.59
                  c-36.251,0-69.658-17.178-90.73-46.3c40.3-29,66.594-76.299,66.594-129.629C320.008,232.7,317.946,217.949,313.877,203.717z
                  M344.144,455.545c-47.793,0-91.57-23.135-118.513-62.222c5.026-2.26,9.914-4.773,14.649-7.523
                  c23.982,33.587,62.275,53.43,103.864,53.43c70.39,0,127.657-57.267,127.657-127.657s-57.267-127.657-127.657-127.657
                  s-127.658,57.267-127.658,127.657c0,10.407,1.266,20.729,3.74,30.742c-4.71,2.987-9.657,5.631-14.806,7.901
                  c-3.482-12.526-5.249-25.488-5.249-38.643c0-79.386,64.586-143.972,143.972-143.972s143.971,64.586,143.971,143.972
                  C488.116,390.961,423.53,455.545,344.144,455.545z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M401.692,85.877l-24.532-37.91h-66.03l-24.521,37.888l32.503,41.363h-15.405v15.642H384v-15.642h-15.084L401.692,85.877z
                  M339.005,127.218l-24.582-31.283h21.61V80.293H308.84l10.798-16.684h49.012l10.798,16.684h-27.774v15.642h22.084l-24.802,31.283
                  H339.005z"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="455.951,127.218 455.951,112.619 440.31,112.619 440.31,127.218 423.625,127.218 423.625,142.859 440.31,142.859 
                  440.31,159.544 455.951,159.544 455.951,142.859 471.593,142.859 471.593,127.218 		"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="40.929" y="32.326" width="15.642" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="63.87" y="32.326" width="25.026" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="8.603" y="32.326" width="23.984" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="40.929" y="55.267" width="15.642" height="25.026"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="40.929" width="15.642" height="23.984"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="80.554" y="64.652" width="15.642" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="471.593" y="80.293" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="423.625" y="56.31" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="135.821,216.896 135.821,200.212 120.179,200.212 120.179,216.896 104.538,216.896 104.538,232.538 
                  120.179,232.538 120.179,247.136 135.821,247.136 135.821,232.538 152.505,232.538 152.505,216.896 		"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="88.896" y="263.821" width="15.642" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="135.821" y="287.805" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <polygon points="368.358,279.462 368.358,262.778 352.717,262.778 352.717,279.462 336.033,279.462 336.033,295.104 
                  352.717,295.104 352.717,311.788 368.358,311.788 368.358,295.104 384,295.104 384,279.462 		"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="391.299" y="247.136" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="368.358" y="327.43" width="15.642" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="495.576" y="200.212" width="15.642" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="121.222" y="7.299" width="14.599" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="135.821" y="464.033" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="104.538" y="464.033" width="23.984" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="160.847" y="464.033" width="22.941" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="135.821" y="431.707" width="15.642" height="22.941"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="135.821" y="486.974" width="15.642" height="25.026"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="96.195" y="431.707" width="16.684" height="15.642"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="56.57" y="486.974" width="16.684" height="15.642"/>
              </g>
            </g>
          </svg>
          </SvgIcon>
        )
      case 'church':
        return (
          <SvgIcon sx={{fontSize: {xs: 50}}}>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <g>
                <path d="M477.42,340.613l-1.419-0.467c-0.001,0-0.002-0.001-0.003-0.001l-116.787-38.448V163.76c0-2.95-1.26-5.598-3.261-7.46
                  c-0.032-0.033-0.054-0.065-0.091-0.098l-89.919-81.474V44.102h14.021c5.633,0,10.199-4.566,10.199-10.199
                  c0-5.633-4.566-10.199-10.199-10.199h-14.021V10.199C265.941,4.566,261.375,0,255.742,0c-5.633,0-10.199,4.566-10.199,10.199
                  v13.505h-14.02c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h14.02v30.632l-89.721,81.475
                  c-0.022,0.02-0.031,0.041-0.05,0.061c-2.019,1.863-3.292,4.524-3.292,7.489v137.942l-116.48,38.442
                  c-0.002,0.001-0.005,0.001-0.007,0.002l-1.419,0.468c-4.18,1.38-7.004,5.284-7.004,9.686v151.5
                  c0,5.633,4.566,10.199,10.199,10.199h436.464c5.633,0,10.199-4.566,10.199-10.199v-151.5
                  C484.431,345.897,481.604,341.99,477.42,340.613z M152.479,491.602H47.966v-131.57h104.512V491.602z M152.479,339.633h-49.846
                  l49.846-16.45V339.633z M255.75,93.02l66.816,60.542H189.08L255.75,93.02z M297.513,491.602h-83.337v-50.933
                  c0-22.61,15.866-41.005,35.368-41.005h12.601c19.502,0,35.368,18.395,35.368,41.005V491.602z M338.813,491.602h-20.901v-50.933
                  c0-33.858-25.017-61.403-55.766-61.403h-12.601c-30.75,0-55.766,27.545-55.766,61.403v50.933h-20.901V349.833V173.96h165.936
                  V491.602z M359.211,323.172l50.002,16.462h-50.002V323.172z M464.033,491.602H359.211v-131.57h104.821V491.602z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M255.742,214.367c-14.614,0-26.505,11.89-26.505,26.505v72.328c0,5.632,4.566,10.199,10.199,10.199h32.61
                  c5.633,0,10.199-4.566,10.199-10.199v-72.328C282.246,226.257,270.357,214.367,255.742,214.367z M261.848,303h-12.212v-62.128
                  c0-3.367,2.74-6.106,6.106-6.106c3.367,0,6.105,2.74,6.105,6.106V303z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M390.752,463.775h-2.061c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h2.061
                  c5.633,0,10.199-4.566,10.199-10.199C400.952,468.341,396.385,463.775,390.752,463.775z"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M446.404,463.775H422.7c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h23.704
                  c5.633,0,10.199-4.566,10.199-10.199C456.604,468.341,452.037,463.775,446.404,463.775z"/>
              </g>
            </g>
            </svg>
          </SvgIcon>
        )
      case 'reception':
        return(
          <SvgIcon sx={{fontSize: {xs: 50}}}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3057 18.2975L8.23724 19.987C5.47183 20.9088 4.08912 21.3697 3.35924 20.6398C2.62936 19.9099 3.09026 18.5272 4.01207 15.7618L5.70156 10.6933C6.46758 8.39525 6.85059 7.24623 7.75684 7.03229C8.6631 6.81835 9.51953 7.67478 11.2324 9.38764L14.6114 12.7666C16.3242 14.4795 17.1807 15.3359 16.9667 16.2422" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M12.2351 18.3461C12.2351 18.3461 11.477 16.0649 11.477 14.5552C11.477 13.0454 12.2351 10.7643 12.2351 10.7643M8.06517 19.4833C8.06517 19.4833 7.42484 16.7314 7.307 14.9343C7.11229 11.965 8.06517 7.35254 8.06517 7.35254" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M14.5093 10.0061L14.6533 9.28614C14.7986 8.55924 15.3224 7.96597 16.0256 7.73155C16.7289 7.49714 17.2526 6.90387 17.398 6.17697L17.542 5.45703" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M17.5693 13.2533L17.7822 13.3762C18.4393 13.7556 19.2655 13.6719 19.8332 13.1685C20.3473 12.7126 21.0794 12.597 21.709 12.8723L22.0005 12.9997" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M9.79513 2.77903C9.45764 3.33109 9.54223 4.04247 9.99976 4.5L10.0976 4.59788C10.4908 4.99104 10.6358 5.56862 10.4749 6.10085" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M6.92761 3.94079C7.13708 3.73132 7.47669 3.73132 7.68616 3.94079C7.89563 4.15026 7.89563 4.48988 7.68616 4.69935C7.47669 4.90882 7.13708 4.90882 6.92761 4.69935C6.71814 4.48988 6.71814 4.15026 6.92761 3.94079Z" fill="#1C274C"/>
              <path d="M12.1571 7.1571C12.3666 6.94763 12.7062 6.94763 12.9157 7.1571C13.1251 7.36657 13.1251 7.70619 12.9157 7.91566C12.7062 8.12512 12.3666 8.12512 12.1571 7.91566C11.9476 7.70619 11.9476 7.36657 12.1571 7.1571Z" fill="#1C274C"/>
              <path d="M17.1571 10.1571C17.3666 9.94763 17.7062 9.94763 17.9157 10.1571C18.1251 10.3666 18.1251 10.7062 17.9157 10.9157C17.7062 11.1251 17.3666 11.1251 17.1571 10.9157C16.9476 10.7062 16.9476 10.3666 17.1571 10.1571Z" fill="#1C274C"/>
              <path d="M19.0582 15.3134C19.2677 15.1039 19.6073 15.1039 19.8168 15.3134C20.0262 15.5228 20.0262 15.8624 19.8168 16.0719C19.6073 16.2814 19.2677 16.2814 19.0582 16.0719C18.8488 15.8624 18.8488 15.5228 19.0582 15.3134Z" fill="#1C274C"/>
              <path d="M19.3615 7.71436C18.6912 8.38463 19.1722 10.328 19.1722 10.328C19.1722 10.328 21.1156 10.809 21.7859 10.1387C22.4958 9.42884 22.0941 8.49708 21.0002 8.5C21.0032 7.40615 20.0714 7.00447 19.3615 7.71436Z" stroke="#1C274C" stroke-linejoin="round"/>
              <path d="M15.1884 3.41748L15.1608 3.51459C15.1305 3.62126 15.1153 3.67459 15.1225 3.72695C15.1296 3.77931 15.1583 3.82476 15.2157 3.91567L15.2679 3.99844C15.4698 4.31836 15.5707 4.47831 15.5019 4.60915C15.4332 4.73998 15.2402 4.75504 14.8544 4.78517L14.7546 4.79296C14.6449 4.80152 14.5901 4.8058 14.5422 4.83099C14.4943 4.85618 14.4587 4.89943 14.3875 4.98592L14.3226 5.06467C14.072 5.36905 13.9467 5.52124 13.8038 5.50167C13.6609 5.4821 13.595 5.30373 13.4632 4.94699L13.4291 4.85469C13.3916 4.75332 13.3729 4.70263 13.3361 4.66584C13.2993 4.62905 13.2486 4.61033 13.1472 4.57287L13.0549 4.53878C12.6982 4.40698 12.5198 4.34108 12.5003 4.19815C12.4807 4.05522 12.6329 3.92992 12.9373 3.67932L13.016 3.61448C13.1025 3.54327 13.1458 3.50767 13.1709 3.45974C13.1961 3.41181 13.2004 3.35699 13.209 3.24735L13.2168 3.14753C13.2469 2.76169 13.262 2.56877 13.3928 2.50001C13.5236 2.43124 13.6836 2.53217 14.0035 2.73403L14.0863 2.78626C14.1772 2.84362 14.2226 2.8723 14.275 2.87947C14.3273 2.88664 14.3807 2.87148 14.4873 2.84117L14.5845 2.81358C14.9598 2.70692 15.1475 2.65359 15.2479 2.75402C15.3483 2.85445 15.295 3.04213 15.1884 3.41748Z" stroke="#1C274C"/>
            </svg>
          </SvgIcon>
        )
      default:
    }
  }


  return Icon()
}

export default SVGCustomIcon
