import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.tsx';
import SVGCustomIcon from '../components/SVGCustomIcon.tsx';


const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const PadrinosViews = () => {
  return (
    <>
      <div style={{backgroundColor: 'black', height: 50, display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant="subtitle1" sx={{ fontFamily: 'philosopher', fontWeight: 600, textAlign:'center', color: 'whitesmoke' }}>
          {'Con el apoyo de nuestros padrinos'}
        </Typography>
      </div>
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
      >
        <Container sx={{ mt: 5, mb: 5, display: 'flex', position: 'relative' }}>
          <Grid container sx={{ justifyContent: 'center'}}>
            <Grid item xs={12} md={4}>
            <Box sx={item}>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Padrinos de Religión'}
                </Typography>
                <SVGCustomIcon iconName='bible'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'Anibal Garbarino'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Carmen Gonzales de Garbarino'}
                </Typography>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Padrinos de Civil'}
                </Typography>
                <SVGCustomIcon iconName='wedding-arch'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Marco Mercado Illanes'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'Carla Talavera de Mercado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Padrinos de Aros'}
                </Typography>
                <SVGCustomIcon iconName='wedding-rings'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Freddy Rogriguez Contreras'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Fresia Mercado de Rogriguez'}
                </Typography>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Padrinos de Torta'}
                </Typography>
                <SVGCustomIcon iconName='wedding-cake'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'Roger Fresco Villanueva'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Lizet Ramirez Flores'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>

  );
}

export default PadrinosViews;