import * as React from 'react';
import Typography from '../components/Typography.tsx';
import ProductHeroLayout from './ProductHeroLayout.tsx';
import Photo from '../assets/lenny-rolo3.png';
import LogoRR from '../assets/imgs/logoRR.png';
import { Box } from '@mui/material';

export default function ProductHero() {

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${Photo})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      <Typography sx={{fontFamily: 'batman', fontSize: {xs: '25px', sm: '50px' }, mb: {xs:8, sm: 25 } }} color='#faeef8' align="center" variant="h2" marked="center">
        Nuestra Boda
      </Typography>
      <Box sx={{
          width: {xs: '40%', sm: '30%', md:'20%'}, 
          height: {xs: '40%', sm: '30%', md:'20%'},
          ml: {xs: '18px', sm: '23px', md:'25px'},
          mt: {xs: '350px', sm: 5},
          mb: {xs: 0, sm: '23px', md:'25px'}
        }}>
        <img
          src={LogoRR}
          alt="logo"
          width="100%"
          height="100%"
          />
      </Box>
      <Typography
        variant='h4'
        align="center"
        color='#faeef8'
        sx={{ fontFamily: 'batman', p: 2 }}
        mt={0}
      >
        Roly & Rossy
      </Typography>

    </ProductHeroLayout>
  );
}