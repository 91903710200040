import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button.tsx';
import Typography from '../components/Typography.tsx';
import SVGCustomIcon from '../components/SVGCustomIcon.tsx';
import Itinerario from '../assets/imgs/Itinerario.jpg'

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const ProductHowItWorks = () => {
  return (
    <Box
      component="section"
      sx={{bgcolor: 'secondary.light'}}
    >
      <Box sx={item}>
        <Typography variant="h5" align="center" sx={{fontFamily: 'philosopher', textAlign: 'center', fontSize: '30px'}} mt={2} >
        {
          '"El amor nos ha llevado a este momento mágico. Los invitamos a ser testigos de nuestra promesa de amor eterno."'
        }
        </Typography>
      </Box>
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
      >
        <Container sx={{ mt: 2, mb: 5, display: 'flex', position: 'relative' }}>
          <Grid container sx={{ justifyContent: 'center'}}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Ceremonia religiosa'}
                </Typography>
                <SVGCustomIcon iconName='church'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Parroquia inmaculada Concepción'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'El Montículo'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'10:45 AM'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'Calle Andres Muñoz Z. Sopocachi'}
                </Typography>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  target="_blank"
                  href="https://www.google.com/maps/place/Iglesia+de+El+Mont%C3%ADculo/@-16.5132257,-68.1275367,15z/data=!4m2!3m1!1s0x0:0x24e8adf2517bfdae?sa=X&ved=1t:2428&ictx=111"
                  sx={{ mt: 2, borderRadius: 1 }}
                >
                  Como llegar
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  {'Recepeción'}
                </Typography>
                <SVGCustomIcon iconName='reception'/>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Salon de eventos Casa Jardin Yarcanu'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                  {'14:30'}
                </Typography>
                <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                  {'Calle Ñ. Los Algarrabos Bajo Llojeta'}
                </Typography>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  target="_blank"
                  href="https://www.google.com/maps/place/Casa+Jard%C3%ADn+Yarcanu/@-16.5286054,-68.1236294,17z/data=!3m1!4b1!4m6!3m5!1s0x915f210e729e404f:0x7510275e4f320fec!8m2!3d-16.5286054!4d-68.1236294!16s%2Fg%2F11rzpzwpj5?entry=ttu"
                  sx={{ mt: 2, borderRadius: 1 }}
                >
                  Como llegar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={'black'} sx={{height: 90, display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant="h3" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center', color: 'white' }}>
          {'Itinerario'}
        </Typography>
      </Box>
      <Box sx={{
          width: {xs: '100%'}, 
          height: {xs: '40%', sm: '30%', md:'20%'},
        }}>
        <img
          src={Itinerario}
          alt="logo"
          width="100%"
          height="100%"
          />
      </Box>
    </Box>
  );
}

export default ProductHowItWorks;