import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.tsx';
import EspecialDate from '../assets/imgs/date4hd.png'
import Countdown from 'react-countdown';
import ReactAudioPlayer from 'react-audio-player'
import Audio from '../assets/audios/cancion.mp3'


const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function DateCounter() {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Typography
      variant='h5'
      align="center"
      color='#000'
      sx={{ fontFamily: 'parisienne', mb: 1, mt: { xs: 2, sm: 0 }, fontSize: 50 }}
    >
      ¡Llego el gran día!
    </Typography>;
    } else {
      return (
        <>
          <ReactAudioPlayer
            src={'https://audiobucketbambique.s3.us-east-2.amazonaws.com/cancion.mp3'}
            autoPlay={true}
            loop
            controls
          />
          <Typography
              variant='h5'
              align="center"
              color='#000'
              sx={{ fontFamily: 'philosopher', color: '#db5266', mb: 1, mt: { xs: 2, sm: 0 }, fontSize: 30 }}
            >
            Solo faltan
          </Typography>
          <Typography
              variant='h5'
              align="center"
              color='#000'
              sx={{ fontFamily: 'philosopher', color: '#db5266'  }}
            >
            {days}:{hours}:{minutes}:{seconds}
          </Typography>
        </>
      ) 
    }
  };
  const now = new Date().getTime();
  const targetDate = new Date(2024, 8, 21, 10, 45, 0, 0);
  const targetTime = targetDate.getTime();
  const difference = targetTime - now;
  return (
    <Box
      component="section"
      sx={{ display: 'flex', alignItems: 'center', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 2, display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
        <Box sx={{
          width: {xs: '100%', sm: '50%', md:'40%'}, 
          height: {xs: '40%', sm: '30%', md:'20%'},
          marginLeft: {xs: '18px', sm: '23px', md:'25px'}
        }}>
        <img
          src={EspecialDate}
          alt="Date"
          width="100%"
          height="100%"
          />
      </Box>
      <Countdown
        date={Date.now() + difference}
        renderer={renderer}
      />
      </Container>
    </Box>
  );
}

export default DateCounter;