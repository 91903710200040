import * as React from 'react';
import ProductHero from '../views/ProductHero.tsx';
import ProductValues from '../views/ProductValues.tsx';
import PadrinosViews from '../views/PadrinosViews.tsx';
import ProductHowItWorks from '../views/ProductHowItWorks.tsx';
import ProductCTA from '../views/ProductCTA.tsx';
import AppAppBar from '../views/AppAppBar.tsx';
import withRoot from '../modules/withRoot.tsx';
import Gallery from '../components/Gallery.tsx';
import { useFlags } from 'flagsmith/react';
import { useNavigate } from 'react-router-dom';
import ConfirmationForm from '../views/ConfirmationForm.tsx';
import DateCounter from '../views/DateCounter.tsx';

const Home = () => {
  const flags = useFlags(['invitations_allowed']);
  const navigate = useNavigate();

  React.useEffect(() =>{
    if (!flags.invitations_allowed.enabled) {
      navigate('/SignIn');
    }
  }, [])

  return (
    <React.Fragment>
      {flags.invitations_allowed.enabled && (
        <>
          <AppAppBar />
          <ProductHero />
          <DateCounter />
          <ProductValues />
          <PadrinosViews />
          <Gallery/>
          <ProductHowItWorks />
          <ProductCTA />
          <ConfirmationForm />
        </>
      )}

    </React.Fragment>
  );
}

export default withRoot(Home);