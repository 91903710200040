import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import './styles/styles.css';
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FlagsmithProvider options={{
      environmentID: "ey6wERoYgpi843DFbqLXJJ",
      cacheFlags: true,
    }} flagsmith={flagsmith}>
      <App />
    </FlagsmithProvider>
  </React.StrictMode>
);

