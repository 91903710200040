import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.tsx';
import Formal from '../assets/imgs/formal.jpg'
import Button from '../components/Button.tsx'
import QrLenny from '../assets/imgs/qrLenny.jpg'
import QrRolo from '../assets/imgs/qrRolo.jpg'


function ProductCTA() {

  const SECONDARY_PINK = '#F8E5F2'
  return (
    <Container component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: {xs: 4, sm: 8},
              px: 3,
              bgcolor: SECONDARY_PINK
            }}
          >
            <Box component="form" sx={{ maxWidth: 400 }}>
              <Typography variant="h2" component="h2" sx={{fontFamily: 'philosopher'}}>
                Vestimenta
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                En este día especial, la formalidad es la regla:
                Trajes, vestidos largos, y sonrisas radiantes.
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                <b>Dress Code: Formal</b>
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                El blanco es un color hermoso, pero en nuestra boda, es exclusivo para la novia.
                Por favor evita rojo, amarillo y azul.
                Todos los demás colores están disponibles
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block',}, position: 'relative' }}
        >
          <Box
            sx={{
              position: {md: 'absolute'} ,
              top: {xs: 0, md: -67},
              left: {xs: 0, md: -67},
              right: 0,
              bottom: 0,
              width: '100%',
              alignContent: 'center'
            }}
          />
          <Box
            component="img"
            src={Formal}
            alt="Formal"
            sx={{
              position: {md: 'absolute'} ,
              top: {xs: 0, md: -28},
              left: {xs: 0, md: -28},
              right: 0,
              bottom: 0,
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ zIndex: 1, mt: 10 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: {xs: 4, sm: 8},
              px: 3,
              bgcolor: SECONDARY_PINK
            }}
          >
            <Box component="form" sx={{ maxWidth: 400 }}>
              <Typography variant="h2" component="h2" sx={{fontFamily: 'philosopher'}}>
                Regalos
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                Su presencia es nuestro mejor regalo. 
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                Si desea hacernos un presente, 
                ponemos a su disposición sugerencias de regalo
                que desearíamos para nuestro hogar.
              </Typography>
              <Button
                color="secondary"
                size="small"
                variant="contained"
                target="_blank"
                href="https://www.tiendaamiga.com.bo/wishlist/shared/index/code/mMbtpKvADdcf0IoobkFHWCDqqJjZxwVH/"
                sx={{ borderRadius: 1, my: 2 }}
              >
                Sugerencias de Regalos
              </Button>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                Como estamos contruyendo nuestro hogar, otra opción de regalo
                que agradeceriamos sería cualquier contribución financiera 
                que nos ayude a establecernos.
              </Typography>
              <Typography variant="h5" sx={{fontFamily: 'philosopher'}}>
                Tu generocidad significa el mundo para nosotros.
              </Typography>
            </Box>

          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block'}, position: 'relative', mt: {xs: 0}  }}
        >
          <Box
            sx={{
              position: {md: 'absolute'} ,
              top: {xs: 0, md: -67},
              left: {xs: 0, md: -67},
              right: 0,
              bottom: 0,
              width: '100%',
              alignContent: 'center'
            }}
          />
          <Box
            component="img"
            src={QrRolo}
            alt="Qrs"
            sx={{
              position: {md: 'absolute'} ,
              top: {xs: 0, md: 190},
              left: {xs: 0, md: -8},
              right: 0,
              bottom: 0,
              width: {xs: '100%', md: '65%'},
            }}
          />
          <Box
            component="img"
            src={QrLenny}
            alt="Qrs"
            sx={{
              position: {md: 'absolute'} ,
              top: {xs: 0, md: 190},
              left: {xs: 0, md: 350},
              right: 0,
              bottom: 0,
              width: {xs: '100%', md: '65%'},
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProductCTA;