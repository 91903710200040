import React from 'react'
import './styles/styles.css'
import Home from './pages/Home.tsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn.tsx';

const App = () => {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/Home" element={<Home />} />
      </Routes>
    </Router>
    </div>
  )
}

export default App
