import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.tsx';
import { Button, TextField } from '@mui/material';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmationForm = () => {
  const flags = useFlags(['invitations_allowed']);
  const [firstInvite, setFirstInvite] = React.useState<string>('')
  const [optionalInvite, setOptionalInvite] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [phone2, setPhone2] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)
  const [canShare, setCanShare] = React.useState<boolean>(false)

  const flagsmith = useFlagsmith();

  const SendData = (firstInvite: string, phone: string, optionalInvite: string = 'no') => {
    const data = {
      name: firstInvite,
      phone: phone,
      optionalInvite: `Acompañante: ${optionalInvite}`
    };

    axios.post('https://0202-189-28-95-235.ngrok-free.app/fill-form', data)
      .then(response => {
        flagsmith.setTrait("completed", true)
        if (flags.invitations_allowed?.enabled && parseInt(flags.invitations_allowed?.value) > 1) {
          flagsmith.identify(`${phone2}`, {"completed": true});
          setCanShare(true)
        }
        console.log("DEBUG: completed:", flagsmith.getTrait("completed"))
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false)
        setError(true)
      });
  }

    const shareData = {
      title: 'Invitación a la boda de Rolo & Rossy',
      text: 'Invitación a la boda de Rolo & Rossy',
      url: 'https://www.mybambique.com/',
    };

    const phoneNumber = "59176543682";
    const message = "Hola, no puedo confirmar mi asistencia";

    const handleClick = () => {
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    };
  
    const handleShare = async () => {
      try {
        if (navigator.share) {
          await navigator.share(shareData);
          console.log('Contenido compartido exitosamente');
        } else {
          console.log('API de compartir no soportada en este navegador');
        }
      } catch (error) {
        console.error('Error al compartir:', error);
      }
    };

    console.log("DEBUG: completed:", flagsmith.getTrait("completed"))
    console.log("DEBUG: completed:", flagsmith.getTrait("type"))

  return (
    <>
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
      >
        <Container sx={{ mt: 5, mb: 5, display: 'flex', justifyContent: 'center'}}>
          <Box
            component="section"
            sx={{ display: 'flex', flexDirection: 'column',  '& .MuiTextField-root': { my: 1, bgcolor: 'white' }, }}>
              {isLoading ? <CircularProgress /> : 
              <>
                <Typography variant="h6" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                  { flags.invitations_allowed?.enabled && parseInt(flags.invitations_allowed?.value) >= 1 ? 'Por favor, confirma tu Asistencia hasta antes del 19 de septiembre' : 'Gracias Por confirmar'}
                </Typography>
                { flags.invitations_allowed?.enabled 
                  && (canShare || (flagsmith.getTrait("type") === 'bi-personal' && flagsmith.getTrait("completed")))
                  && (
                    <Button onClick={() => handleShare()} 
                      color="secondary"
                      size="medium"
                      variant="contained">
                      Comparte esta invitación con tu acompañante
                    </Button>
                    )
                }
                {flags.invitations_allowed?.enabled && parseInt(flags.invitations_allowed?.value) >= 1 && (
                  <>
                  <TextField
                    id="outlined-basic"
                    label="Invitado 1"
                    variant="outlined"
                    value={firstInvite}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstInvite(event.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="telefono"
                    variant="outlined"
                    type='number'
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(event.target.value);
                    }}
                  />
                  {flags.invitations_allowed?.enabled && parseInt(flags.invitations_allowed?.value) > 1 && (
                    <>
                      <TextField
                        id="outlined-basic" 
                        label="Invitado 2"
                        variant="outlined"
                        value={optionalInvite}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setOptionalInvite(event.target.value);
                        }} 
                      />
                      <TextField
                        id="outlined-basic"
                        label="telefono"
                        variant="outlined"
                        type='number'
                        value={phone2}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setPhone2(event.target.value);
                        }}
                      />
                    </>
                  )}
                  <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    sx={{ borderRadius: 1, my: 2 }}
                    disabled={
                      !firstInvite
                        || !phone
                        || (parseInt(flags.invitations_allowed?.value) > 1 && (!optionalInvite || !phone2))
                      }
                    onClick={() => {
                      setIsLoading(true)
                      SendData(firstInvite, phone, optionalInvite)
                    }}
                  >
                  {'Confirmar'}
                  </Button>
                  {error && (
                    <button onClick={handleClick} style={{ padding: '10px 20px', backgroundColor: '#25D366', color: 'white', border: 'none', borderRadius: '5px' }}>
                      ¿Crees que hubo un error al confirmar tu asistencia? Contactanos.
                    </button>
                  )}
                </>
              )}
              </>
            }
          </Box>
        </Container>
      </Box>
    </>

  );
}

export default ConfirmationForm;