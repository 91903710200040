import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.tsx';
import Rings from '../assets/imgs/aros.png' 

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 5, mb: 5, display: 'flex', position: 'relative' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={Rings}
                alt="rings"
                sx={{ height: 55, my: 2 }}
              />
              <Typography variant="h5" sx={{fontFamily: 'parisienne', textAlign: 'center'}} mt={2} >
                {
                  'No fuiste antes ni después, fuiste a tiempo. A tiempo para que me enamorara de ti'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
          <Box sx={item}>
              <Typography variant="h5" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                {'Con la bendición de Dios y nuestros padres.'}
              </Typography>
              <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                {'Padres del novio'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                {'(Papá)  Rolando Gonzales Pinto Escalier'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                {'(Mamá) Mabel Selum Arce'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Typography variant="subtitle1" sx={{ my: 2, fontFamily: 'philosopher', fontWeight: 600, textAlign:'center' }}>
                {'Padres de la novia'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center' }}>
                {'(Abuelo) ✟ Max Ramirez Illanes'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                {'(Abuela) Maria Simona Flores Vda. de Ramirez'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                {'(Mamá) Lourdes Gilda Ramirez de Roque'}
              </Typography>
              <Typography variant="body1" sx={{ my: 1, fontFamily: 'philosopher', textAlign:'center'  }}>
                {'(Papá) Hugo Roque Vasquez'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;